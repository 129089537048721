<template>
  <div class="dashboard-card bg-white rounded-sm">
    <!-- Content -->

    <div class="p-2">
      <p class="text-caption text-gray">RFQ NO.{{ data.id | rfqFormat }}</p>

      <!-- Buyer Info -->

      <div class="dashboard-card-buyer d-flex align-items-center">
        <div class="dashboard-card-buyer-img">
          <img :src="buyer_img" class="rounded-circle" height="50" />
        </div>

        <div class="ml-2">
          <h5 class="font-style-primary m-0">
            {{ data.user.information | userFullName }}
          </h5>

          <p class="text-small text-gray-dark-3 m-0">
            {{ data.user.information | userCompany }}
          </p>
        </div>
      </div>

      <!-- Order Details -->

      <div class="dashboard-card-order row mt-3">
        <div class="col-6">
          <p class="dashboard-card-label m-0">PROJECT NAME</p>

          <p class="text-body-2 font-weight-normal text-truncate">
            {{ data.project_name || data.order.project_name }}
          </p>
        </div>

        <div class="col-6">
          <p class="dashboard-card-label m-0">ORDER DATE</p>

          <span class="dashboard-card-date font-weight-bold">
            {{ data.created_at | readableDate }}
          </span>

          <span class="dashboard-card-time ml-1 font-weight-normal">
            {{ data.created_at | readableTime }}
          </span>
        </div>

        <div class="col-6">
          <p class="dashboard-card-label m-0">END BIDDING DATE</p>

          <span class="dashboard-card-date font-weight-bold">
            {{ data.bidding_end_date | readableDate(false, true) }}
          </span>

          <span class="dashboard-card-time ml-1 font-weight-normal">
            {{ data.bidding_end_date | readableTime(true) }}
          </span>
        </div>

        <div class="col-6">
          <p class="dashboard-card-label m-0">DELIVERY DATE</p>

          <span class="dashboard-card-date font-weight-bold">
            {{ data.delivery_date | readableDate(false, true) }}
          </span>
        </div>
      </div>

      <!-- Items -->

      <div class="dashboard-card-items mt-3">
        <p class="dashboard-card-label m-0">ITEMS ({{ items.length }})</p>

        <div class="row ml-n1 mr-n1">
          <div
            v-for="(item, index) in items"
            :key="`item-img-${index}`"
            class="col-1 p-1"
          >
            <AppMediaViewer :files="itemFiles(item)" class="w-100 h-100" />
          </div>
        </div>
      </div>
    </div>

    <!-- Actions -->

    <div class="dashboard-card-action w-100">
      <!-- request -->

      <router-link
        v-if="type === 'request'"
        class="
          dashboard-card-action-request
          p-1
          d-flex
          align-items-center
          justify-content-center
          text-info
        "
        :to="{ name: 'OrderView', params: { id: data.id } }"
      >
        <AppIcon name="send"></AppIcon>

        <label class="m-0 ml-1 font-weight-normal">View Request</label>
      </router-link>

      <!-- pending -->

      <router-link
        v-else-if="type === 'pending'"
        class="
          dashboard-card-action-pending
          p-1
          d-flex
          align-items-center
          justify-content-center
          text-info
        "
        :to="{ name: 'OrderView', params: { id: data.id } }"
      >
        <AppIcon name="document"></AppIcon>

        <label class="m-0 ml-1 font-weight-normal">Quote Sent</label>

        <label class="m-0 ml-auto font-weight-normal">Review</label>
      </router-link>

      <!-- approved -->

      <router-link
        v-else-if="type === 'approved'"
        class="
          dashboard-card-action-approved
          p-1
          d-flex
          align-items-center
          justify-content-center
          text-success
        "
        :to="{ name: 'QuoteView', params: { id: data.quotations[0].id } }"
      >
        <AppIcon name="checkmark"></AppIcon>

        <label class="m-0 ml-1 font-weight-normal">
          Quote Approved by Customer
        </label>

        <label class="m-0 ml-auto font-weight-normal">View</label>
      </router-link>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon';

import _rfqFormat from '@/shared/mixins/_rfqFormat';

import _readableDateTime from '@/shared/mixins/_readableDateTime';

import AppIcon from '../../../shared/elements/AppIcon.vue';

import AppMediaViewer from '../../../shared/elements/AppMediaViewer.vue';

export default {
  name: 'VendorDashboardCard',

  components: { AppIcon, AppMediaViewer },

  mixins: [_readableDateTime, _rfqFormat],

  props: {
    rqf: { type: [String, Number], default: 0 },

    buyer: { type: String, default: '' },

    buyer_img: { type: String, default: '' },

    company: { type: String, default: '' },

    project: { type: String, default: '' },

    order_date: { type: String, default: '' },

    end_date: { type: String, default: '' },

    delivery_date: { type: String, default: '' },

    items: { type: Array, default: () => [] },

    type: {
      type: String,

      default: 'request',

      validator: (value) =>
        ['request', 'pending', 'approved'].indexOf(value) !== -1,
    },

    data: { type: Object, default: () => ({}) },
  },

  computed: {
    dateNow() {
      return DateTime.now();
    },
  },

  filters: {
    userFullName(value) {
      if (value && (value.first_name || value.last_name)) {
        return `${value.first_name} ${value.last_name} `;
      } else {
        return 'N/A';
      }
    },

    userCompany(value) {
      if (value && value.company_name) {
        return value.company_name;
      } else {
        return 'N/A';
      }
    },
  },

  methods: {
    itemFiles(item) {
      return item?.product?.files ? item.product.files : [];
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/theme/_font';
@import '@/scss/theme/_colors';

.dashboard-card {
  &-label {
    @extend .text-small;

    @extend .text-gray-dark-3;
  }

  &-date {
    @extend .text-body-2;
  }

  &-time {
    @extend .text-body-2;

    @extend .text-gray-dark-4;
  }

  &-action {
    * {
      cursor: pointer;
    }

    &-request,
    &-pending,
    &-approved {
      transition: background-color 0.3s;

      @extend .bg-gray-light-4;

      &:hover {
        @extend .bg-info-light-5;
      }
    }

    &-request {
      overflow: hidden;

      max-height: 0;

      opacity: 0;

      transition: max-height 0.3s, opacity 0.3s, background-color 0.3s;
    }
  }

  &:hover {
    .dashboard-card-action {
      &-request {
        max-height: 40px;

        opacity: 1;
      }
    }
  }
}
</style>
