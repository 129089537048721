<template>
  <div class="dashboard-card-holder h-100">
    <div class="bg-gray-light-3 h-100 d-flex flex-column">
      <!-- Header -->
      <div
        class="
          dashboard-card-holder-header-icon
          bg-white
          p-2
          d-flex
          align-items-end
          shadow-60
        "
      >
        <div class="dashboard-card-holder-header-icon">
          <slot name="prepend-icon">
            <SvgQuotationRequest></SvgQuotationRequest>
          </slot>
        </div>
        <h3
          class="
            text-md-headline-1
            dashboard-card-holder-header-counter
            m-0
            ml-2
            mb-n1 mb-md-n2
            p-0
          "
        >
          {{ counter }}
        </h3>
        <p
          class="
            dashboard-card-holder-header-title
            m-0
            ml-2
            text-small text-uppercase
            font-weight-5 font-weight-md-6
          "
        >
          {{ title }}
        </p>
      </div>
      <!-- Content -->
      <div
        class="
          dashborad-card-holder-content
          p-2
          border border-gray-light-1
          h-100
          overflow-auto
          flex-shrink-1
        "
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import SvgQuotationRequest from './SVGComponent/SvgQuotationRequest.vue';
export default {
  name: 'DashboardCardHolder',

  components: { SvgQuotationRequest },

  props: {
    counter: { type: [String, Number], default: 0 },
    title: { type: String, default: '' },
  },
};
</script>

<style lang="scss" scoped></style>
