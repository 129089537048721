<template>
  <transition name="dashboard-notif">
    <div
      v-if="innerVal"
      class="
        dashboard-notif
        p-0
        btn-primary-light-6
        border border-primary border-1
        text-primary-dark-2
        d-flex
        flex-column flex-md-row
      "
    >
      <div
        class="pt-3 pr-3 bg-primary-light-4 d-none d-md-flex"
        style="width: 140px"
      >
        <SvgBrownEnvelope></SvgBrownEnvelope>
      </div>
      <div class="content p-2 w-100">
        <div class="d-flex align-items-center">
          <div
            class="mr-2 pt-1 pr-1 d-flex d-md-none bg-primary-light-4"
            style="height: 32px; width: 32px"
          >
            <SvgBrownEnvelope></SvgBrownEnvelope>
          </div>
          <h6 class="text-xl-headline-5 text-truncate text-uppercase">
            <slot name="title"></slot>
          </h6>
        </div>
        <span class="d-none d-md-block text-caption text-xl-body-2">
          <slot name="message"></slot>
        </span>
      </div>
      <div
        class="
          view-all
          d-none d-md-flex
          align-items-center
          justify-content-center justify-content-md-end
        "
      >
        <router-link class="btn-text-primary-dark-1 m-0 mr-4" :to="goTo">
          <span class="text-caption text-xl-body-2">View All</span>
        </router-link>
        <AppIcon
          hoverable
          name="close"
          class="mr-4"
          @click="innerVal = false"
        ></AppIcon>
      </div>
    </div>
  </transition>
</template>

<script>
import AppIcon from '@/shared/elements/AppIcon.vue';
import SvgBrownEnvelope from './SVGComponent/SvgBrownEnvelope.vue';
import _appDefaultInput from '@/shared/mixins/_appDefaultInput';

export default {
  name: 'DashboardNotifications',

  components: { AppIcon, SvgBrownEnvelope },

  mixins: [_appDefaultInput],

  props: {
    color: { type: String, default: 'primary' },
    goTo: { type: [String, Object], default: '#' },
  },

  data() {
    return {
      innerVal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/theme/_breakpoints';
.view-all {
  width: 25%;
  @include media-md('below') {
    width: 100%;
  }
}
.dashboard-notif {
  max-height: 300px;
  &-enter-active,
  &-leave-active {
    transition: max-height 0.15s ease-out;
  }
  &-enter,
  &-leave-to {
    max-height: 0;
  }
}
</style>
