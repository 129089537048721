<template functional>
  <svg viewBox="0 0 79 68" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M58.0553 7.89634L76.926 39.9974C79.1525 43.7848 77.8249 48.6507 73.9686 50.8374L13.3254 85.2241C9.46915 87.4108 4.51468 86.1069 2.28825 82.3196L-16.5825 50.2185L28.2222 48.3515C30.5646 48.245 32.7415 47.0106 34.0066 45.0715L58.0553 7.89634Z"
      fill="#F38725"
    />
    <path
      d="M56.2783 4.87205L58.0558 7.89585L34.0072 45.071C32.7421 47.0101 30.5651 48.2445 28.2227 48.351L-16.582 50.218L-18.3595 47.1942C-20.586 43.4068 -19.2584 38.5409 -15.4021 36.3542L45.2411 1.9675C49.0974 -0.219148 54.0518 1.08468 56.2783 4.87205Z"
      fill="#CC6600"
    />
  </svg>
</template>
<script>
export default {
  name: 'SvgBrownEnvelope',
}
</script>
